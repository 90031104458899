import React from 'react';
import PropTypes from 'prop-types';
import Block from './Block';

const Quote = ({image, title, quote, caption, variant}) => {
    const textVariant = variant === 'primary' ? 'white' : 'primary';
    return (
        <Block variant={variant} image={image}>
            <h2 className={`text-${textVariant}`}>{title}</h2>
            <figure>
                <blockquote className="blockquote text-white">
                    <p>{quote}</p>
                </blockquote>
                <figcaption className={`blockquote-footer text-${textVariant}`}>{caption}</figcaption>
            </figure>
        </Block>
    )
};

Quote.propTypes = {
    image: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    quote: PropTypes.node.isRequired,
    caption: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary']),
}

Quote.defaultProps = {
    variant: 'primary',
}

export default Quote;
