import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import {Link} from 'gatsby';
import styled from 'styled-components';

const Overlay = styled(Card.ImgOverlay)`
    background: var(--bs-secondary);
    background: linear-gradient(0deg, var(--bs-secondary) 0%, rgba(0, 0, 0, 0) 50%);
`;

const TileCard = styled(Card)`
    img {
        filter: grayscale(1);
    }

    &:hover img {
        filter: none;
    }

    h2 {
        width: 100%;
    }
`;

const Tile = ({children, title, to}) => {
    return (
        <Link to={to}>
            <TileCard>
                {React.cloneElement(
                    React.Children.only(children),
                    {
                        alt: title,
                        imgClassName: 'img-fluid',
                        className: 'card-img'
                    }
                )}
                <Overlay>
                    <Card.Title as="h2"
                                className="position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
                        {title}
                    </Card.Title>
                </Overlay>
            </TileCard>
        </Link>
    )
};

Tile.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default Tile;
