import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import AudioCard from 'audiocard';

const CardText = styled(Card.Text)`
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
`;

const Title = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    height: 80px;
`;

const PlayIcon = styled.i`
    display: block;
    color: var(--bs-primary);
    font-size: 3rem;
`;

const GrossUndQuasligFeedCard = ({podcast, player, onPlayClick}) => {
    return (
        <Card className="h-100">
            <Card.Body>
                <Card.Title className="d-flex">
                    {player ? (
                        <div style={{height: 80}} className="flex-fill">
                            <div style={{width: '90%', overflow: 'hidden'}} className="mx-auto">
                                <AudioCard source={podcast.enclosure.url} skipBackSeconds={10} skipForwardSeconds={30}
                                           autoPlay={true} link={podcast.link} linkText="Details"
                                           title={podcast.title}/>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Title className="flex-fill">{podcast.title}</Title>
                            {onPlayClick && (
                                <PlayIcon className="bi-play-circle" onClick={() => onPlayClick(podcast)}/>
                            )}
                        </>
                    )}
                </Card.Title>
                <CardText>{podcast.contentSnippet}</CardText>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">{podcast.isoDate}</small>
            </Card.Footer>
        </Card>
    );
};

GrossUndQuasligFeedCard.propTypes = {
    podcast: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        contentSnippet: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        isoDate: PropTypes.string.isRequired,
        itunes: PropTypes.shape({
            image: PropTypes.string.isRequired,
        }).isRequired,
        enclosure: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    player: PropTypes.bool,
    onPlayClick: PropTypes.func,
};

GrossUndQuasligFeedCard.defaultProps = {
    player: false,
}

export default GrossUndQuasligFeedCard;
