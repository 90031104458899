import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {graphql, useStaticQuery} from 'gatsby';
import GrossUndQuasligFeedCard from './GrossUndQuasligFeedCard';
import {StaticImage} from 'gatsby-plugin-image';
import GroupedCarousel from './GroupedCarousel';

const GrossUndQuasligFeed = ({...rest}) => {
    const {
        allFeedGrossUndQuasligPodcast: {nodes: grossUndQuasligFeed},
    } = useStaticQuery(graphql`
        query {
            allFeedGrossUndQuasligPodcast(limit: 12) {
                nodes {
                    id
                    title
                    contentSnippet
                    isoDate(fromNow: true, locale: "de")
                    link
                    itunes {
                        image
                    }
                    enclosure {
                        url
                    }
                }
            }
        }
    `);

    const [selected, setSelected] = useState(null);

    return (
        <div {...rest}>
            <Row>
                <Col sm={4} className="text-center">
                    <StaticImage src="../images/gross-und-quaslig.jpg" height={250} alt="gross & quaslig"/>
                </Col>
                <Col sm={8}>
                    <h2 className="mt-3 mt-md-0">Podcast</h2>
                    <figure>
                        <blockquote className="blockquote">
                            <p>Wenn viel Meinung und wenig Ahnung Hand in Hand gehen und dabei noch über einen
                                emotionalen Sport wie Skaterhockey gesprochen wird, dann ertönt „groß & quaslig“ in den
                                Ohren. Mit netten Anekdoten, Insiderwissen und der ein oder anderen Kontroverse
                                berichten die beiden Bundesligaspieler über ihre Leidenschaft und Liebe zu dieser
                                rasanten Sportart und was rundherum noch so passiert. Nachdem Skaterhockey nicht jedem
                                sofort ein bekannter Begriff ist, ziehen die beiden immer wieder Vergleiche zur
                                ähnelnden Sportart Eishockey.</p>
                        </blockquote>
                        <figcaption className="blockquote-footer">
                            gross & quaslig auf <a href="https://anchor.fm/grossundquaslig" target="_blank"
                                                   rel="noreferrer">Anchor</a>
                        </figcaption>
                    </figure>
                </Col>
            </Row>
            <hr/>
            <GroupedCarousel items={grossUndQuasligFeed}>
                {(p) => (
                    <GrossUndQuasligFeedCard key={p.id} podcast={p} player={selected === p.id}
                                             onPlayClick={() => setSelected(p.id)}/>
                )}
            </GroupedCarousel>
            <hr/>
        </div>
    );
};

export default GrossUndQuasligFeed;
