import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Figure} from 'react-bootstrap';

const Number = styled.div`
    font-family: Montserrat, sans-serif;
    font-size: calc(var(--font-size-base) * 4.5);
    font-weight: var(--font-weight-bold);
`;

const KpiFigure = ({figure, caption}) => {
    return (
        <Figure className="text-center">
            <Number className="text-white">{figure}</Number>
            <Figure.Caption className="text-white">{caption}</Figure.Caption>
        </Figure>
    )
};

KpiFigure.propTypes = {
    figure: PropTypes.node.isRequired,
    caption: PropTypes.node.isRequired,
};

export default KpiFigure;
