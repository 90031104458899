import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {graphql, useStaticQuery} from 'gatsby';
import IshdNewsFeedCard from './IshdNewsFeedCard';
import {StaticImage} from 'gatsby-plugin-image';
import GroupedCarousel from './GroupedCarousel';

const IshdNewsFeed = ({...rest}) => {
    const {
        allFeedIshdNews: {nodes: ishdNewsFeed}
    } = useStaticQuery(graphql`
        query {
            allFeedIshdNews(limit: 12) {
                nodes {
                    id
                    title
                    contentSnippet
                    isoDate(fromNow: true, locale: "de")
                    link
                }
            }
        }
    `);

    return (
        <div {...rest}>
            <Row>
                <Col sm={8}>
                    <h2>Skaterhockey News</h2>
                    <p>Hier gibt es ein paar Infos von der ISHD-Verbandsseite, damit ihr auch hier auf dem neuesten
                        Stand seid.</p>
                </Col>
                <Col sm={4} className="text-center">
                    <StaticImage src="../images/ishd.png" height={180} alt="ishd.de"/>
                </Col>
            </Row>
            <hr/>
            <GroupedCarousel items={ishdNewsFeed}>
                {(n) => (
                    <IshdNewsFeedCard news={n}/>
                )}
            </GroupedCarousel>
            <hr/>
        </div>
    );
};

export default IshdNewsFeed;
