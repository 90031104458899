import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import styled from 'styled-components';

import videoBlurImage from '../images/video_blur.png';

const VideoBlur = styled.div`
    background-image: url(${videoBlurImage});
    background-repeat: repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const IndexImage = ({...rest}) => {
    return (
        <div {...rest}>
            <div className="position-relative">
                <div className="ratio ratio-16x9 bg-secondary">
                    <iframe
                        allowFullScreen
                        src={`https://www.youtube.com/embed/lfhyzhn7Ncw?hl=de&autoplay=1&mute=1&playsinline=1&loop=1&modestbranding=1&rel=0&controls=0&disablekb=1&fs=0`}
                        title="skaterhockey.de"
                        allow="autoplay; fullscreen; encrypted-media; picture-in-picture"/>
                </div>
                <VideoBlur/>
                <StaticImage src="../images/sh_logo_transparent_no_fill.svg" alt="skaterhockey.de" height={400}
                             placeholder="none"
                             className="position-absolute d-block top-50 start-50 translate-middle"/>
            </div>
        </div>
    );
};

export default IndexImage;
