import React from 'react';
import {Helmet} from 'react-helmet';
import {Col, Row} from 'react-bootstrap';
import {StaticImage} from 'gatsby-plugin-image';

import IshdNewsFeed from '../components/IshdNewsFeed';
import GrossUndQuasligFeed from '../components/GrossUndQuasligFeed';
import IndexImage from '../components/IndexImage';
import ContactForm from '../components/form/ContactForm';
import KpiFigure from '../components/KpiFigure';
import QuestionsAndAnswers from '../components/QuestionsAndAnswers';
import Quote from '../components/Quote';
import Tile from '../components/Tile';
import Block from '../components/Block';
import InstagramLink from '../components/InstagramLink';
import FacebookLink from '../components/FacebookLink';
import SocialMediaBlock from '../components/SocialMediaBlock';
import ImpressionBlock from '../components/ImpressionBlock';

const IndexPage = () => {
    return (
        <>
            <Helmet title="Inline-Skaterhockey"/>
            <IndexImage/>

            <Block variant="light">
                <h1>Herzlich Willkommen</h1>
                <p>…beim <span className="text-white bg-primary">#skaterhockey</span>. Auf diesen Seiten wollen wir dich
                    über unseren Sport informieren, dir unsere Philosophie und unsere Werte nahebringen und dir
                    Kontaktmöglichkeiten aufzeigen.</p>

                <p>Du willst direkt weitergehende Infos haben? Hier geht es zu unseren Unterseiten für:</p>

                <Row xs={1} md={3} className="g-2 g-md-5">
                    <Col>
                        <Tile to="/spieler" title={"Spieler\u200b:innen"}>
                            <StaticImage src="../images/demo/demo8.jpg" alt="Spieler" height={400}
                                         aspectRatio={1} className="card-img" imgClassName="img-fluid"/>
                        </Tile>
                    </Col>
                    <Col>
                        <Tile to="/eltern" title="Eltern">
                            <StaticImage src="../images/eltern/eltern1.jpg" alt="Eltern" height={400}
                                         aspectRatio={1} className="card-img" imgClassName="img-fluid"/>
                        </Tile>
                    </Col>
                    <Col>
                        <Tile to="/teams-und-vereine" title="Teams">
                            <StaticImage src="../images/demo/demo7.jpg" alt="Teams & Vereine" height={400}
                                         aspectRatio={1} className="card-img" imgClassName="img-fluid"/>
                        </Tile>
                    </Col>
                </Row>
            </Block>

            <Block variant="secondary" image={(
                <StaticImage src="../images/demo/demo6.jpg" alt="Skaterhockey?" width={600}/>
            )}>
                <h2>Was ist Skaterhockey?</h2>
                <p className="text-white">Skaterhockey ist eine runde Sache - denn unsere wichtigsten Bestandteile sind
                    acht Rollen an den Füßen und ein Ball - und der muss mit dem Schläger ins Tor.</p>

                <p className="text-white">Skaterhockey ist schnell, dynamisch, action- und vor allem abwechslungsreich.
                    Unsere Sportart besteht aus Inlineskaten, präzisem Passspiel, zielgenauen Torschüssen, taktischen
                    Finessen und fairem Körpereinsatz.</p>

                <p className="text-white">Skaterhockey wird von der Landesliga bis zur Bundesliga und von den Bambini
                    (U10) bis in die Junioren-Altersklasse (U19) organisiert gespielt.</p>
            </Block>
            <Block variant="primary">
                <h2 className="text-white">Skaterhockey in Zahlen</h2>
                <p className="text-white">Skaterhockey funktioniert fast überall: Auf dem Parkplatz, in der Sporthalle,
                    in abgetauten Eishallen. Warum? Wir spielen auf Inlinern und mit Ball. Unser Sport ist schnell,
                    aufregend, begeisternd, faszinierend. Er wird dich packen - und nicht mehr loslassen. Überzeugt?
                    Oder brauchst du noch ein paar Zahlen?</p>
                <Row xs={1} md={3}>
                    <Col className="text-center">
                        <KpiFigure figure="1986" caption="Organisierter Spielbetrieb seit 1986"/>
                    </Col>
                    <Col className="text-center">
                        <KpiFigure figure="250" caption="registrierte und am Spielbetrieb teilnehmende Vereine"/>
                    </Col>
                    <Col className="text-center">
                        <KpiFigure figure="9.000"
                                   caption="Spieler:innen und Teammitglieder, die unsere Leidenschaft teilen"/>
                    </Col>
                </Row>
            </Block>

            <Block>
                <GrossUndQuasligFeed/>
            </Block>

            <Block variant="light">
                <IshdNewsFeed/>
            </Block>

            <Quote variant="secondary"
                   image={<StaticImage src="../images/vater_zitat.jpg" alt="Ein toller Mannschaftssport" height={300}/>}
                   title="Ein toller Mannschaftssport"
                   quote={
                       <>
                           Meine Tochter Julia ist mit vier Jahren in die Laufschule gekommen. Danach hat sie in der
                           Bambinimannschaft begonnen, steht mittlerweile im Tor und hat dabei riesigen Spaß.
                           Skaterhockey ist ein toller Mannschaftssport. Der Zusammenhalt begeistert auch uns Eltern.
                           Auch wir engagieren uns, wenn im Verein Unterstützung gefragt ist
                       </>
                   }
                   caption="Horst S., Vater aus Atting"
            />

            <Block>
                <h2 id="contact">Kontakt</h2>
                <p>Du hast Interesse an unserem Sport und möchtest mehr erfahren? Sprich uns
                    bei <FacebookLink/> oder <InstagramLink/> an oder nutze das Kontaktformular!</p>
                <ContactForm topic="index"/>
            </Block>

            <Block variant="light">
                <h2>Skaterhockey FAQ</h2>
                <p>Jede Menge nützliche Infos rund ums Skaterhockey</p>
                <QuestionsAndAnswers questions={[{
                    question: 'Was brauche ich?',
                    answer: 'Inlineskates und Schutzausrüstung wie beim Eishockey.'
                }, {
                    question: 'Wo bekomme ich das her?',
                    answer: 'Spezielle Onlineshops bieten Einsteiger-Ausrüstungspakete. Im Fachhandel vor Ort kannst du sie anprobieren. Gebrauchte Ausrüstung gibt es bei den Clubs oder bei Onlineauktionshäusern.'
                }, {
                    question: 'Welche Altersklassen gibt es?',
                    answer: 'Es gibt die fünf Altersklassen U10 (zehn Jahre oder jünger), U13, U16, U19 und Herren/Damen.'
                }, {
                    question: 'Kann ich den Sport auch als Mädchen ausprobieren?',
                    answer: 'Selbstverständlich. Mädchen trainieren zusammen mit den Jungs in einem Team. Einige Clubs haben auch Damenmannschaften im Trainings- oder Spielbetrieb.'
                }, {
                    question: 'Wann kann man Skaterhockey spielen?',
                    answer: 'Die Sportart kann das ganze Jahr über gespielt werden. Die meisten Vereine spielen in eigenen Hallen (Turnhallen, Skaterhockey-Hallen, vereinzelt Eisstadien) oder auch auf Freiplätzen (diese sind dann witterungsabhängig).'
                }, {
                    question: 'Ich spiele bereits Eishockey, das ist mir aber zu stressig.',
                    answer: 'Dann bist du im Skaterhockey genau richtig. Denn du bringst schon ideale Vorkenntnisse mit. Die Trainings- und Spielzeiten lassen sich außerdem hervorragend mit der Schule und deinen anderen Aktivitäten verbinden.'
                }]}/>
            </Block>

            <SocialMediaBlock/>

            <ImpressionBlock alt="Impressionen ">
                <StaticImage src="../images/impressions/herren_flug.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/schneider_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_torwart_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_tor_szene_5.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_pain.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/freistoss_ball.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_bank.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_bully_8.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_zweikampf_7.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/damen_sieger.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/schiedsrichter_bully_1.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_bully_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_bully_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/eltern_4.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_spieler_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/trainer.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_hymne.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_torwart_3.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_angriff.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_jubel_3.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u19_sieger_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_zweikampf_2.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
            </ImpressionBlock>
        </>
    );
};

export default IndexPage
