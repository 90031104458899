import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card} from 'react-bootstrap';
import styled from 'styled-components';

const CardTitle = styled(Card.Title)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CardText = styled(Card.Text)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const IshdNewsFeedCard = ({news}) => {
    return (
        <Card className="h-100">
            <Card.Body>
                <CardTitle>{news.title}</CardTitle>
                <CardText>{news.contentSnippet}</CardText>
                <Button variant="primary" href={news.link} target="_blank" rel="noreferrer">weiterlesen</Button>
            </Card.Body>
            <Card.Footer>
                <small className=" text-muted">{news.isoDate}</small>
            </Card.Footer>
        </Card>
    );
};

IshdNewsFeedCard.propTypes = {
    news: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        contentSnippet: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        isoDate: PropTypes.string.isRequired,
    }).isRequired,
}

export default IshdNewsFeedCard;
